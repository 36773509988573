import { module } from 'modujs';
import modularLoad from 'modularload';
import { html } from '../utils/environment';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        const load = new modularLoad({
            enterDelay: 500,
        });

        load.on('loaded', (transition, oldContainer, newContainer) => {
            this.call('destroy', oldContainer, 'app');
            this.call('update', newContainer, 'app');
        });
        
        load.on('loading', (transition, oldContainer) => {
            html.classList.remove('has-navOpen');
            html.classList.remove('has-flapOpen');
            html.classList.remove('has-menuOpen');
            html.classList.add('is-transitioning');
        });

        load.on('ready', (transition, newContainer) => {
          html.classList.add('is-transitioned');
          html.classList.remove('is-transitioning');
          this.call('play', '', 'Lottie');
        });

    }
}
