import { module } from 'modujs';
import lottieWeb from 'lottie-web';

export default class extends module {
    constructor(m) {
        super(m);

        this.animation = null;
    }

    init() {
      let json = this.getData('json');
      let title = this.getData('title');
      let loop = false;
      let autoplay = true;
      if(this.getData('loop')) {
        loop = true;
      }
      if(this.getData('delay')) {
        autoplay = false;
      }
      
      this.animation = lottieWeb.loadAnimation({
        container: this.el,
        path: json,
        renderer: 'svg',
        loop: loop,
        autoplay: autoplay,
        name: title,
      });

      if(this.getData('delay')) {
        let $this = this;
        setTimeout(function(){
          $this.play();
        }, 1500);
      }
    }

    play() {
      this.animation.play();
      setTimeout(function(){
        $('.lottie-loop').removeClass('is-hidden');
        $('.lottie-intro').addClass('is-hidden');
      }, 2000);
    }
}