import { module as modular } from 'modujs';

export default class extends modular {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                btn: 'togglefilters',
                reset: 'reset',
                select: 'select',
                open: 'toggleSidebar',
                resetall: 'resetall',
                refresh: 'refresh',
                pagination: 'pagination',
                changeMonth: 'refreshMonth',
                reset: 'reset',
                checked: 'checked',
                refreshCentre: 'refreshCentre',
            },
            change: {
                change: 'refresh',
            },
        };

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    }

    init() {
        if (this.getData('init')) {
            setTimeout(() => {
                this.refresh();
            }, 500);
        }
    }

    refresh() {
        let form = $(this.el);
        let t = this;
        jQuery.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: form.serialize(),
            success: function (response) {
                if ($('.b-relation_listing').length) {
                    $('.b-relation_listing').html(response.content);
                }
                if($('.b-relation_counter').length && response.counter){
                    $('.b-relation_counter').html(response.counter);
                }

                setTimeout(() => {
                    if(response.closemodal) {
                        t.call('closeFiltres', false, 'Modal');
                    }
                    t.call('update', false, 'Scroll'); // update Locomotive Scroll
                    t.call('scrollTo', '.b-relation_listing', 'Scroll');
                }, 500);
            },
        });
    }

    refreshCentre() {
        let form = $(this.el);
        let t = this;
        jQuery.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: form.serialize(),
            success: function (response) {
                // console.log(response);
                if (response.content && $('.b-relation-centres_posts').length) {
                    $('.b-relation-centres_posts').html(response.content);
                }
                if(response.lieux) {
                    t.call('updateMap', response.lieux, 'Map');
                }
                if(response.count && $('.b-relation-centres_count').length) {
                    $('.b-relation-centres_count').html(response.count);
                }
                setTimeout(() => {
                    t.call('closeFiltres', false, 'Modal');
                    t.call('update', false, 'Scroll'); // update Locomotive Scroll
                    t.call('scrollTo', 0, 'Scroll');
                }, 500);
            },
        });
    }

    checked(e) {
        const target = e.currentTarget;
        const items = target;
        let check = false;

        for (let i = 0; i < 4; i++) {
            if (items[i].classList.contains('is-active')) {
                items[i].classList.remove('is-active');
                check == true;
            }
        }

        if (check !== true) {
            item.classList.add('-checked');
        }
    }
}
