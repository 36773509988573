import { module as modular } from 'modujs';
import $ from 'jquery';
import datepickerFactory from 'jquery-datepicker';
import datepickerFRFactory from 'jquery-datepicker/i18n/jquery.ui.datepicker-fr';

datepickerFactory($);
datepickerFRFactory($);

export default class extends modular {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                
            },
        };

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
        this.dates_calendar = [];
    }

    init() {
        if(this.getData('datepicker') && this.getData('target')) {
            let $datepicker = $(this.getData('datepicker'));
            let $target = this.getData('target');
            let $this = this;
            
            $.datepicker.regional['fr'];

            // Fire beforeShow
            $.extend($.datepicker, {

                // Reference the orignal function so we can override it and call it later
                _inlineDatepicker2: $.datepicker._inlineDatepicker,
        
                // Override the _inlineDatepicker method
                _inlineDatepicker: function (target, inst) {
        
                    // Call the original
                    this._inlineDatepicker2(target, inst);
        
                    var beforeShow = $.datepicker._get(inst, 'beforeShow');
        
                    if (beforeShow) {
                        beforeShow.apply(target, [target, inst]);
                    }
                }
            });

            $datepicker.datepicker('destroy');
            $datepicker.datepicker({
                firstDay: 1,
                dateFormat: 'yymmdd',
                prevText: '<svg xmlns="http://www.w3.org/2000/svg" width="9.944" height="18.167" viewBox="0 0 9.944 18.167"><path id="Tracé_65" data-name="Tracé 65" d="M-1.165,0-8,8.117l6.835,8.117" transform="translate(9.961 0.966)" fill="none" stroke="#fff" stroke-width="3"/></svg>',
                nextText: '<svg xmlns="http://www.w3.org/2000/svg" width="9.944" height="18.167" viewBox="0 0 9.944 18.167"><path id="Tracé_66" data-name="Tracé 66" d="M-8,0l6.835,8.117L-8,16.234" transform="translate(9.147 0.966)" fill="none" stroke="#fff" stroke-width="3"/></svg>',
                // currentText: 'Aujourd\'hui',
                // monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                // monthNamesShort: ['Janv.', 'Févr.', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'],
                // dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
                // dayNamesShort: ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'],
                // dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
                // weekHeader: 'Sem.',
                beforeShow: $this.check_calendar(),
                onChangeMonthYear: $this.check_calendar(),
                onSelect: function(date) {
                    $.ajax({
                        url: $this.ajaxurl,
                        type: 'POST',
                        data: {'action': 'refresh_calendar', 'date': date},
                        success: function (response) {
                            $($target).html(response.content);
                        },
                    })
                },
            });
            function beforeShowDay(date) {
                return $this.beforeShowDay(date);
            }
            setTimeout(() => {
                $datepicker.datepicker( 'option', 'beforeShowDay', beforeShowDay );
            }, 1000);
        }
    }

    check_calendar() {
        let $this = this;
        $.ajax({
            url: $this.ajaxurl,
            type: 'POST',
            // async: false,
            data: {'action': 'check_calendar'},
            success: function (response) {
                $this.dates_calendar = response;
            },
        });
    }

    beforeShowDay(date) {
        date = $.datepicker.formatDate('yymmdd', date);
        if($.inArray(date, this.dates_calendar) == -1) {
            return [true, '', ''];
        }else {
            return [true, 'day-event', ''];
        }
    }
}